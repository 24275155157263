// eslint-disable-next-line no-redeclare,no-unused-vars
function overview(param, xhr = {}) {
  var view = param.view;
  var object = param.object;
  var objectSub = param.objectSub;

  if (!param.ident) param.ident = [object, objectSub].filter(Boolean).join('_');
  param.overview = true;
  param.title = getParamSettings(param, 'label');
  if (xhr.amount) param.total = xhr.amount;
  if (xhr.tableFields) param.tableFields = xhr.tableFields;
  param.type = getParamSettings(param, 'type');
  if (!param.type) param.type = 'table';
  if (isRead(getParamSettings(param, 'read'))) param.read = true;

  // picker
  if (param.picker) {
    param.buttons = {};
    param.buttons['Übernehmen'] = function () {
      var selected = view.table.selected();
      if (!selected.length) {
        showInfo(info.pickedEmpty, 'warning');
        return;
      }
      param.picker(selected, view);
    };
    param.buttons[param.title + ' erstellen'] = function () {
      var pm = {buttons: {}};
      pm.buttons['Übernehmen'] = function (param2) {
        if (param2.id) {
          param2.close = 'prev';
          param.picker(param2.id, param2.view);
        }
      };
      view.new(pm);
    };
  }

  // add new button
  // if ((!param.total || param.picker) && !param.read && !param.noButtons && !getParamSettings(param, 'noAdd')) {
  // 	param.preButton = {};
  // 	param.preButton[param.title + ' erstellen'] = 'new';
  // }

  // build view
  if (!view) {
    param.bodyCard = true;
    if (param.modal) view = buildPopup(param);
    else {
      view = buildView(param);
      if (view === false) return;
      if (getScreenHeight() > 500) {
        var subs = getParamSettings(param, 'subs');
        if (subs) buildSubBar(view.header, subs, param);
      }
    }
  }

  // build content
  param.view = view;
  if (param.type == 'calendar') buildCalendar(view.body, param, xhr);
  else if (param.type == 'chart') buildChart(view.body, param, xhr);
  else new buildTable(view.body, param, xhr.ids, xhr.sum);
  addSearchBar(view.body, param).prependTo(view.body);

  view.updateData = function () {
    view.table.loadData({refresh: true, update: true});
  };
  view.new = function (param2) {
    if (view.table.deselectAll) view.table.deselectAll();
    var pm = {object: object, objectSub: objectSub, top: view};
    if (param.modal) pm.modal = true;
    if (param.adopt) pm.adopt = param.adopt;
    if (param2) Object.assign(pm, param2);
    // if (test && isEmptyObject(fields[object])) pm.savePrompt = true;
    detail(pm);
  };
  if (param.openNew) view.new();

  if (!param.noHeader && !param.noButtons) addButtonBar(param);
  if (!param.modal) {
    handleMenuActive(param);
    setTimeout(function () {
      view.setHeight();
    }, 500);
  }

  if (test && user.admin && view && view.overview) administrateObject(param);
  return view;
}
